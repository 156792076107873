import { useState, useEffect } from "react";
import useTransaction from "../../hooks/useTransaction";
import Popover from "../Popover";
import Checkbox from "../Checkbox";
import RefundModal from "./RefundModal";
import Row from "./Row";

export default function TransactionsTable() {
  const { rows, filterState, setFilterState } = useTransaction();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [expandedTransactions, setExpandedTransactions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const expandAllRefundRows = () => {
    const refundedRowsTransactionId = rows.map((row) => {
      if (row.refunded > 0) {
        return row.transaction_id;
      }
    });
    setExpandedTransactions(refundedRowsTransactionId);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [rows]);
  const shrinkAllRefundRows = () => {
    setExpandedTransactions([]);
  };
  const rowsPerPage = 15;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = rows?.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(rows?.length / rowsPerPage);

  const handleCheckboxChange = (label) => {
    setFilterState({
      ...filterState,
      [label]: !filterState[label],
    });
    setIsPopoverOpen(false);
  };
  const openModal = (e, transaction) => {
    e.stopPropagation();
    setCurrentTransaction(transaction);
    setIsModalVisible(true);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto p-6 ">
      <div className="overflow-x-auto ">
        <RefundModal
          transaction={currentTransaction}
          isVisible={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
        <table className="min-w-full  shadow-md rounded-lg overflow-hidden ">
          <thead className="bg-main-dark  text-main-light text-xs ">
            <tr className="rounded-full text-sm ">
              <th className="py-4 px-4 uppercase font-semibold ">
                Date de Règlement
              </th>
              <th className="py-4 px-4 uppercase font-semibold ">Client ID</th>
              <th className="py-4 px-4 uppercase font-semibold ">Prénom</th>
              <th className="py-4 px-4 uppercase font-semibold ">Nom</th>
              <th className="py-4 px-4 uppercase font-semibold ">
                Transaction ID
              </th>
              <th className="py-4 px-4 uppercase font-semibold ">Statut</th>
              <th className="py-4 px-4 uppercase font-semibold ">Montant</th>
              <th className="py-4 px-4 uppercase font-semibold ">Frais</th>
              <th
                className={`py-4 pl-6  flex justify-center items-center w-fit font-medium ${
                  (!filterState?.showNonRefund ||
                    !filterState?.showRefund ||
                    !filterState?.showPendingRefund) &&
                  "-mt-2 -mb-3"
                }`}
              >
                <Popover
                  content={
                    <div className="text-sm text-black text-left py-1 space-y-2 w-[220px]">
                      <Checkbox
                        label="remboursé"
                        checked={filterState?.showRefund}
                        onChange={() => {
                          handleCheckboxChange("showRefund");
                        }}
                      />
                      <Checkbox
                        label="en attente de validation"
                        checked={filterState?.showPendingRefund}
                        onChange={() => {
                          handleCheckboxChange("showPendingRefund");
                        }}
                      />
                      <Checkbox
                        label="non remboursé"
                        checked={filterState?.showNonRefund}
                        onChange={() => {
                          handleCheckboxChange("showNonRefund");
                        }}
                      />
                      <div className="flex flex-col  items-center gap-1 pt-3">
                        <button
                          className="text-center border-2 border-black rounded-lg px-2 hover:bg-black hover:text-white"
                          onClick={expandAllRefundRows}
                        >
                          tout déplier
                        </button>

                        <button
                          className="text-center border-2 border-black rounded-lg px-2 hover:bg-black hover:text-white"
                          onClick={shrinkAllRefundRows}
                        >
                          tout replier
                        </button>
                      </div>
                    </div>
                  }
                  isVisible={isPopoverOpen}
                  setIsVisible={setIsPopoverOpen}
                >
                  <button className="flex justify-center items-center gap-3 cursor-pointer  uppercase font-semibold ">
                    Remboursement
                    <div
                      className={`${
                        (!filterState?.showNonRefund ||
                          !filterState?.showRefund) &&
                        "rounded-full border p-2 border-white border-[2px]"
                      }`}
                    >
                      <img
                        src="/filter.svg"
                        width={15}
                        height={15}
                        alt="filter"
                        className="flex  justify-center"
                      />
                    </div>
                  </button>
                </Popover>
              </th>
            </tr>
          </thead>

          <tbody className="text-gray-700 text-center text-main-light ">
            <tr className="h-[8px]"></tr>
            {currentRows?.map((transaction) => (
              <Row
                transaction={transaction}
                openModal={openModal}
                expandedTransactions={expandedTransactions}
                setExpandedTransactions={setExpandedTransactions}
                key={transaction.transaction_id}
              />
            ))}
            <tr className={`${rows?.length < 2 && "h-[300px]"} `}></tr>
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <nav className="inline-flex">
          <ul className="pagination flex ">
            <li>
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="py-2 px-4 font-semibold rounded-full  text-white text-xl hover:bg-main-dark focus:outline-none focus:bg-main-dark relative right-10"
              >
                {"<"}
              </button>
            </li>
            {currentPage > 2 && (
              <>
                <li>
                  <button
                    onClick={() => paginate(1)}
                    className="py-2 px-4 font-semibold rounded-full text-white hover:bg-main-dark "
                  >
                    1
                  </button>
                </li>
                {currentPage > 3 && (
                  <li className="py-2 px-4 text-white">...</li>
                )}
              </>
            )}
            {currentPage > 1 && (
              <li>
                <button
                  onClick={() => paginate(currentPage - 1)}
                  className="py-2 px-4 font-semibold rounded-full text-white  "
                >
                  {currentPage - 1}
                </button>
              </li>
            )}
            <li>
              <button className="py-2 px-4 font-semibold rounded-full bg-main-dark text-white">
                {currentPage}
              </button>
            </li>
            {currentPage < totalPages && (
              <li>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  className="py-2 px-4 font-semibold rounded-full  text-white  "
                >
                  {currentPage + 1}
                </button>
              </li>
            )}
            {currentPage < totalPages - 1 && (
              <>
                {currentPage < totalPages - 2 && (
                  <li className="py-2 px-4 text-white">...</li>
                )}
                <li>
                  <button
                    onClick={() => paginate(totalPages)}
                    className="py-2 px-4 font-semibold rounded-full text-white hover:bg-main-dark focus:outline-none focus:bg-main-dark"
                  >
                    {totalPages}
                  </button>
                </li>
              </>
            )}
            <li>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
                className="py-2 px-4 font-semibold rounded-full  text-white text-xl hover:bg-main-dark focus:outline-none focus:bg-main-dark relative left-10"
              >
                {">"}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
