import React from 'react'

export default function AddPointsPopup({ onClose }) {
  return (
    <div className='absolute w-full h-full backdrop-blur-sm z-10 flex flex-col items-center rounded'>
      <div className='relative border w-1/4 h-80 mt-8 bg-white flex flex-col shadow-md justify-center items-center'>
        <button className='absolute top-2 right-2 text-lg font-bold' onClick={onClose}>
          &times;
        </button>
        <select className='w-1/5 h-8 border mb-4'>
          <option value='mint'>mint</option>
          <option value='burn'>burn</option>
        </select>
        <label className='font-semibold text-[1.25rem] text-left w-2/3'>
          Amount
          <input className='mt-2 w-full bg-[#d9d9d9] pl-2 font-medium text-[1.12rem] h-10 outline-[#bbbaba]' type='text' name='amount' />
        </label>
        <label className='font-semibold text-[1.25rem] text-left w-2/3'>
          user ID
          <input className='mt-2 w-full bg-[#d9d9d9] pl-2 font-medium text-[1.12rem] h-10 outline-[#bbbaba]' type='text' name='userId' />
        </label>
        <button className='w-40 h-10 border rounded bg-pink-red text-[1.125rem] text-white font-semibold mt-4'>Envoyer</button>
      </div>
    </div>
  )
}