import React from "react";
import Card from "../components/menu/Card";
import Header from "../components/Header";
const cards = [
  {
    title: "Facturation et Opérations",
    description:
      "Suivi des opérations et des facturations, dont les remboursements.",
    buttonAction: "/transactions",
    imagePath: "/menu/wallet.svg",
  },
  {
    title: "Programme B Partner Club",
    description:
      "Paramétrage B Partner Club, et suivi des évènements de gains/mint de points.",
    buttonAction: "/coinOverview",
    imagePath: "/menu/person.svg",
  },
  {
    title: "Gestion des cartes",
    description:
      "Gestion des cartes de paiement, permet de réaliser les actes de gestion à la place du client.e. mise en opposition...",
    buttonAction: "",
    imagePath: "/menu/cards.svg",
  },
  {
    title: "Enregistrement OFX",
    description:
      "Gestion et suivi des enregistrements chez OFX, partenaire de gestion des devises.",
    buttonAction: "",
    imagePath: "/menu/ofx.svg",
  },
  {
    title: "Code invitation Prestige",
    description: "Gestion et suivi des codes d'invitation au compte Prestige.",
    buttonAction: "",
    imagePath: "/menu/letter.svg",
  },
];

export default function Menu() {
  return (
    <main>
      <Header />
      <div className="w-[1440px]  relative ">
        <div className="w-[201px] h-12 left-[1183px]  absolute" />
        <div className=" left-[225px] top-[138px] absolute flex-col justify-start items-start gap-6 inline-flex">
          <div className=" flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-[#0b0c0d] text-4xl font-bold font-['Poppins'] leading-[46.80px]">
              Bienvenue,
            </div>
            <div className="self-stretch text-[#8d8e90] text-lg font-semibold font-['Poppins'] leading-[27px]">
              Que voulez-vous faire ?
            </div>
          </div>
          <div className="self-stretch justify-start items-end gap-8 inline-flex">
            {cards.map((card, index) => (
              <Card key={index} {...card} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
