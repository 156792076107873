import React, { useEffect } from "react";
import DateRangePicker from "flowbite-datepicker/DateRangePicker";
import useTransaction from "../hooks/useTransaction";
const CustomDateRangePicker = () => {
  const { dateRangePickerRef, filterState } = useTransaction();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const dateRangePickerEl = document.getElementById("dateRangePickerId");
      if (dateRangePickerEl) {
        const dateRangePicker = new DateRangePicker(dateRangePickerEl, {
          format: "dd/mm/yyyy",
        });
        dateRangePickerRef.current = dateRangePicker;
      }
    }
  }, []);

  return (
    <div className="flex items-center" id="dateRangePickerId">
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
          </svg>
        </div>
        <input
          name="start"
          type="text"
          defaultValue={filterState?.dates[0]}
          className="bg-gray-50 bg-main-light border border-main-light text-gray-900 text-sm rounded-full focus:ring-gray-500 focus:border-gray-500 block w-full ps-10 p-2.5 "
        />
      </div>
      <span className="mx-4 text-main-light">-</span>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
          </svg>
        </div>
        <input
          name="end"
          type="text"
          defaultValue={filterState?.dates[1]}
          className=" bg-main-light border border-main-light text-gray-900 text-sm rounded-full focus:ring-gray-500 focus:border-gray-500 block w-full ps-10 p-2.5 "
        />
      </div>
    </div>
  );
};

export default CustomDateRangePicker;
