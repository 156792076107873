import RefundRow from "./RefundRow";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
const Row = ({
  transaction,
  openModal,
  expandedTransactions,
  setExpandedTransactions,
}) => {
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} `;
    const formattedTime = `${hours}:${minutes}`;
    return (
      <div className="flex justify-center gap-4">
        <p>{formattedDate}</p>
        <span className="rounded-full bg-gray-dark px-2">{formattedTime}</span>
      </div>
    );
  }
  const transactionId = transaction?.transaction_id;
  const handleRefundClick = (e) => {
    e.stopPropagation();
    if (expandedTransactions.includes(transactionId)) {
      setExpandedTransactions(
        expandedTransactions.filter((el) => el !== transactionId)
      );
    } else {
      setExpandedTransactions([...expandedTransactions, transactionId]);
    }
  };
  return (
    <Fragment>
      <tr
        className="  bg-gray-dark hover:bg-gray2 cursor-pointer "
        onClick={() => {
          navigate(`/transaction/${transaction.transaction_id}`);
        }}
      >
        <td className="py-3 px-1 bg-gray2 rounded-l-lg">
          {formatDate(transaction.transaction_settlement_date)}
        </td>
        <td className="py-3 px-4  flex justify-center ">
          <p className="rounded-full bg-gray2 w-fit px-4 text-main-light">
            {transaction.client_id}
          </p>
        </td>
        <td className="py-3 px-4 ">{transaction.first_name}</td>
        <td className="py-3 px-4 ">{transaction.last_name}</td>
        <td className="py-3 px-4  flex justify-center items-center">
          <p className="rounded-full bg-gray2 w-fit px-4 text-main-light ">
            {transaction.transaction_id}
          </p>
        </td>
        <td className="py-3 px-4">{transaction.transaction_status}</td>
        <td className="py-3 px-2 flex justify-center gap-2">
          {transaction.amount}

          {transaction.currency == "EUR" && " \u20AC"}
          {transaction.transaction_refund_amount > 0 && (
            <span className="px-2 rounded-full flex flex-row items-center bg-gray2 bg-opacity-40 w-fit gap-1">
              -{transaction.transaction_refund_amount}
              <img
                src="/refund.svg"
                width={25}
                height={25}
                alt="logo refund"
                className="flex items-center"
              />
            </span>
          )}
        </td>
        <td className=" py-3 px-2 ">
          <div className="flex justify-center gap-2">
            {transaction.fees_amount && (
              <>
                {transaction.fees_amount}
                {transaction.currency_fees == "EUR" && " \u20AC"}
              </>
            )}
            {transaction.fees_refund_amount > 0 && (
              <span className="px-2 rounded-full flex flex-row items-center bg-gray2 bg-opacity-40 w-fit gap-1">
                -{transaction.fees_refund_amount}
                <img
                  src="/refund.svg"
                  width={25}
                  height={25}
                  alt="logo refund"
                  className="flex items-center"
                />
              </span>
            )}
          </div>
        </td>
        <td className="w-fit  ">
          <div className="grid grid-cols-3  justify-items-center w-fit relative -right-[15px] ">
            {transaction?.refunded > 0 && (
              <div className="flex group" onClick={handleRefundClick}>
                <img
                  src="/refund.svg"
                  width={35}
                  height={35}
                  alt="logo refund"
                  className={` rounded-full w-[39px] ${
                    [2, 3].includes(transaction?.refunded) &&
                    "bg-main-dark group-hover:bg-main  "
                  } ${
                    transaction?.refunded == 1 &&
                    "bg-gray2 group-hover:bg-gray-dark  "
                  } `}
                />
                <div
                  className={`p-[2px] mt-7 -ml-2  rounded-full ${
                    [2, 3].includes(transaction?.refunded) &&
                    "bg-main-dark group-hover:bg-main "
                  } ${
                    transaction?.refunded == 1 &&
                    "bg-gray3 group-hover:bg-gray-dark  "
                  } `}
                >
                  {expandedTransactions.includes(transaction.transaction_id) ? (
                    <img
                      src="/up-arrow.svg"
                      width={12}
                      height={12}
                      alt="up arrow"
                    />
                  ) : (
                    <img
                      src="/down-arrow.svg"
                      width={12}
                      height={12}
                      alt="down arrow"
                    />
                  )}
                </div>
              </div>
            )}
            <div className="flex items-center col-start-2 col-span-2">
              <button
                className="px-2  text-main-dark border-2 border-main-dark hover:bg-main-dark hover:text-white rounded-lg  h-fit "
                onClick={(e) => {
                  openModal(e, transaction);
                }}
              >
                rembourser
              </button>
            </div>
          </div>
        </td>
      </tr>
      {expandedTransactions.includes(transaction.transaction_id) && (
        <tr>
          <td colSpan="9">
            <RefundRow
              transactionId={transaction.transaction_id}
              transactionFeesId={transaction?.transaction_fees_id}
              maxTransactionRefundAmount={
                transaction?.amount - transaction?.transaction_refund_amount
              }
              maxFeesRefundAmount={
                transaction?.fees_amount - transaction?.fees_refund_amount
              }
            />
          </td>
        </tr>
      )}
      <tr className="h-[4px]"></tr>
    </Fragment>
  );
};
export default Row;
