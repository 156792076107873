// src/components/Table.js
import React from "react";

const Table = ({ data }) => {
  return (
    <table className="min-w-full border-collapse border border-gray-300 rounded-lg ">
      <tbody>
        {data.map((item, index) => {
          if (item.value) {
            return (
              <tr key={index}>
                <td className="border px-4 py-2">{item.label}</td>
                <td className="border px-4 py-2">{item.value}</td>
              </tr>
            );
          } else {
            return null; // or handle the case where item.value is falsy
          }
        })}
      </tbody>
    </table>
  );
};

export default Table;
