import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import axios from "axios";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setUser(null);
        return;
      }
      try {
        const url = `${apiUrl}/getUserFromToken`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(res.data.user);
      } catch (err) {
        setUser(null);
      }
    };
    checkAuth();
  }, []);
  const login = async (loginState) => {
    try {
      const response = await axios.post(`${apiUrl}/login`, loginState);
      const token = response.data.token;
      localStorage.setItem("token", token);

      const res = await axios.get(`${apiUrl}/getUserFromToken`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(res.data.user);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          throw new Error("Non autorisé:  username ou mot de passe incorrect.");
        } else if (err.response.status === 500) {
          throw new Error("Internal Server Error. Please try again later.");
        } else {
          throw new Error(
            `Error: ${err.response.status} - ${
              err.response.data.message || "An error occurred"
            }`
          );
        }
      } else if (err.request) {
        throw new Error("Network Error. Please check your connection.");
      } else {
        throw new Error(`Error: ${err.message}`);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const value = useMemo(() => ({ user, login, logout }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return useContext(AuthContext);
}
