import React from 'react';

const Card = ({ eventId, event, target, formula, isSelected, onCardClick, name }) => {
    return (
        <div 
            className={`flex flex-col w-[18%] min-w-[250px] rounded p-4 border-2 text-left pl-12 font-Poppins cursor-pointer py-8 ${isSelected ? 'border-[#cc133e]' : 'border-gray-400'}`}
            onClick={onCardClick}
        >
            <div className="text-pink-red">event ID: <span className='text-black'>{eventId}</span></div>
            <div className="text-pink-red">event: <span className='text-black'>{event}</span></div>
            <div className="text-pink-red">formula: <span className='text-black'>{formula}</span></div>
            <div className="text-pink-red">target: <span className='text-black'>{target}</span></div>
            <div className="text-pink-red">name: <span className='text-black'>{name}</span></div>
        </div>
    );
};

export default Card;