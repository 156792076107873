import { useEffect } from "react";

const Modal = ({ isVisible, onClose, children }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="bg-gray-dark text-white rounded-lg p-6 relative max-w-lg mx-auto w-full">
        <button
          className="absolute top-3 right-5 text-gray-400 hover:text-gray-200 text-3xl"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
