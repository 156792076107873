import React from 'react'

export default function LignAccDesk(props) {
  return (
    <div className={`flex flex-row w-full ${props.colored === true ? 'bg-[#D9D9D94D]':''} justify-end my-1 items-center`}>
        <div className='flex flex-col text-left mr-auto font-Poppins text-[0.90rem]'>
            <p className={`flex items-center ${props.bold === undefined ? '' : 'text-[1.125rem] font-semibold'}`}>{props.title}</p>
            {props.subtitle === '' ? '' : 
            <p className='text-[#98949E]'>{props.subtitle}</p>}
        </div>
        <div className=' h-fit w-[18rem] flex justify-center mr-[3.8rem]'>
            {props.col1 === 'loading' ? 
            <div className='w-[1rem] h-[1rem] loader'></div> :
            <p className='font-Poppins text-[0.90rem]'>{props.col1}</p>}
        </div>
        <div className=' h-fit w-[18rem] flex justify-center mr-[2rem]'>
            {props.col2 === 'loading' ? 
            <div className='w-[1rem] h-[1rem] loader'></div> :
            <p className='font-Poppins text-[0.90rem]'>{props.col2}</p>}
        </div>
    </div>
  )
}
