import React, { useState, useEffect, useRef } from "react";

const Dropdown = ({ label, items, onSelect = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (item, closeOnClick) => {
    onSelect(item);
    if (closeOnClick) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="relative inline-block text-left w-[160px]"
    >
      <button onClick={handleToggle}>{label}</button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2  w-full rounded-2xl shadow-lg bg-main-light ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelect(item.item, item.closeOnClick)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:rounded-2xl hover:text-gray-900 w-full text-left"
                role="menuitem"
              >
                {item.item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
