import { useState } from "react";
import Modal from "../Modal";
import useTransaction from "../../hooks/useTransaction";
import useAuth from "../../hooks/useAuth";
const RefundModal = ({ isVisible, onClose, transaction }) => {
  const [refundAmount, setRefundAmount] = useState("");
  const [refundType, setRefundType] = useState("transaction");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const {
    createPendingRefund,
    triggerRefund,
    getCurrentDateTimeForSQL,
    refresh,
    setRefresh,
  } = useTransaction();
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const maxAmount =
      refundType == "transaction"
        ? transaction?.amount - transaction?.transaction_refund_amount
        : transaction?.fees_amount - transaction?.transaction_fees_amount;
    const currency =
      refundType == "transaction"
        ? transaction?.currency
        : transaction?.currency_fees;
    if (refundAmount > maxAmount) {
      setError(`Le montant doit être inférieur à ${maxAmount}`);
    } else {
      try {
        const refundRow = {
          dashboard_user_id: user.userId,
          refund_amount: refundAmount,
          refund_description: description,
          refund_currency: currency,
          refund_date: getCurrentDateTimeForSQL(),
        };
        if (refundType == "transaction") {
          refundRow["transaction_id"] = transaction?.transaction_id;
          refundRow["fee_type"] = "transaction";
        } else {
          refundRow["transaction_fees_id"] = transaction?.transaction_fees_id;
          refundRow["fee_type"] = "frais";
        }
        if (user?.userType === "regular") {
          await createPendingRefund(refundRow);
        }
        if (user?.userType === "admin") {
          await triggerRefund(refundRow, transaction?.transaction_id);
        }
        setTimeout(() => {
          setRefresh(!refresh);
        }, 1000);
        onClose();
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} className="w-[500px]">
      <form onSubmit={handleSubmit}>
        <h2 className="text-xl mb-4">Détails du remboursement</h2>
        <div className="bg-gray2 rounded-lg p-2">
          <h3>
            transaction{" "}
            <span className="bg-gray-dark rounded-full px-2">
              {transaction?.transaction_id}
            </span>
          </h3>
          <div className="grid grid-cols-2">
            <h4>
              montant restant :{" "}
              {transaction?.amount - transaction?.transaction_refund_amount}
              {transaction?.currency == "EUR" && " \u20AC"}
            </h4>
            {transaction?.fees_amount && (
              <h3>
                frais restant :{" "}
                {transaction?.fees_amount -
                  transaction?.transaction_fees_amount}{" "}
                {transaction?.currency_fees == "EUR" && " \u20AC"}
              </h3>
            )}
          </div>
        </div>
        {error && <h3 className="text-main">{error}</h3>}
        <div className="my-6">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="refundType"
          >
            Type de remboursement
          </label>
          <select
            id="refundType"
            value={refundType}
            onChange={(e) => setRefundType(e.target.value)}
            className="w-full px-3 py-2 bg-gray3 text-white rounded-md  focus:outline-none  focus:border-white focus:ring-0"
            required
          >
            <option value="transaction">Transaction</option>
            <option value="fees">Frais</option>
          </select>
        </div>
        <div className="my-4">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="refundAmount"
          >
            Montant (max:{" "}
            {refundType == "transaction" ? (
              <>
                {transaction?.amount}
                {transaction?.currency == "EUR" && " \u20AC"}
              </>
            ) : (
              <>
                {transaction?.fees_amount}
                {transaction?.currency_fees == "EUR" && " \u20AC"}
              </>
            )}
            )
          </label>
          <input
            type="number"
            id="refundAmount"
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
            className="w-full px-3 py-2 bg-gray3 text-white rounded-md focus:outline-none  focus:border-white focus:ring-0"
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 bg-gray3 text-white rounded-md focus:outline-none  focus:border-white focus:ring-0"
            rows="4"
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-main text-white rounded-md hover:bg-main"
          >
            Valider
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RefundModal;
