import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Card from "../components/eventsComponents/Card"; // Assurez-vous que le chemin est correct

const ModifyEvent = () => {
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [name, setName] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");
  const [eventTarget, setEventTarget] = useState("All");
  const [cashbackFormula, setCashbackFormula] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(
          `https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events?id=${eventId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEventDetails(data);
        setName(data.planName);
        setSelectedEventId(data.eventId);
        setEventTarget(data.planTarget);
        setCashbackFormula(data.planFormula.split(" * ")[0]);
        setIsActive(data.isActive);
      } catch (error) {
        setMessage("Failed to fetch event details: " + error.message);
      }
    };

    const fetchEventOptions = async () => {
      try {
        const response = await fetch(
          "https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events/event_details"
        );
        const data = await response.json();
        setEventOptions(data);
      } catch (error) {
        console.error("Error fetching event options:", error);
      }
    };

    fetchEventDetails();
    fetchEventOptions();
  }, [eventId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !selectedEventId || !eventTarget || !cashbackFormula) {
      setMessage("Please fill out all fields.");
      return;
    }

    const updatedEventDetails = {
      eventId: selectedEventId,
      planTarget: eventTarget,
      planFormula: `${cashbackFormula} * amount`,
      planName: name,
      isActive,
    };
    try {
      const response = await fetch(
        `https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events/update?id=${eventId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedEventDetails),
        }
      );

      if (response.ok) {
        setMessage("Event updated successfully!");
      } else {
        setMessage("Failed to update event.");
      }
    } catch (error) {
      setMessage("An error occurred: " + error.message);
    }
  };

  return (
    <div>
      <Header />
      <h1 className="text-[2rem] mt-8 font-Poppins font-bold">Modify Event</h1>
      {eventDetails ? (
        <div>
          <div className="w-fit mx-auto translate-x-7">
            <Card
              eventId={eventId}
              event={eventDetails.eventId}
              target={eventDetails.planTarget}
              formula={eventDetails.planFormula}
              name={eventDetails.planName}
            />
          </div>
          <form
            onSubmit={handleSubmit}
            className="mt-8 space-y-4 w-[30rem] mx-auto"
          >
            <div className="flex flex-col">
              <label className="font-Poppins text-lg">Event Name</label>
              <select
                value={selectedEventId}
                onChange={(e) => setSelectedEventId(e.target.value)}
                className="border border-gray-400 rounded p-2"
              >
                <option value="">Select an event</option>
                {eventOptions.map((event) => (
                  <option key={event.eventId} value={event.eventId}>
                    {event.eventType}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="font-Poppins text-lg">Target</label>
              <select
                value={eventTarget}
                onChange={(e) => setEventTarget(e.target.value)}
                className="border border-gray-400 rounded p-2"
              >
                <option value="All">All</option>
                <option value="prestige">prestige</option>
                <option value="premium">premium</option>
                <option value="prestige pro">prestige pro</option>
                <option value="premium pro">premium pro</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="font-Poppins text-lg">Formula</label>
              <div className="flex items-center">
                <span className="mr-2">Amount * </span>
                <input
                  type="text"
                  value={cashbackFormula}
                  onChange={(e) => setCashbackFormula(e.target.value)}
                  className="border border-gray-400 rounded p-2 flex-grow"
                />
                <span className="ml-2"> = cashback</span>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-Poppins text-lg">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border border-gray-400 rounded p-2"
              />
            </div>
            <div className="flex flex-col">
              <label className="font-Poppins text-lg">Active</label>
              <select
                value={isActive}
                onChange={(e) => setIsActive(e.target.value === "true")}
                className="border border-gray-400 rounded p-2"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <button
              type="submit"
              className="bg-pink-red text-white py-2 px-4 rounded hover:bg-dark-pink-red"
            >
              Update Event
            </button>
          </form>
        </div>
      ) : (
        <p>Loading event details...</p>
      )}
      {message && <p className="mt-4 font-Poppins text-lg">{message}</p>}
    </div>
  );
};

export default ModifyEvent;
