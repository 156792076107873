import React from 'react';

export default function LignArray(props) {
  return (
    <div className='flex border-b flex-row w-full border-bottom mt-2 group relative'>
        <p className='text-center w-full font-Poppins text-[1rem]'>{props.col1}</p>
        <p className='text-center w-full font-Poppins text-[1rem]'>{props.col2}</p>
        <p className='text-center w-full font-Poppins text-[1rem]'>{props.col3}</p>
        <p className='text-center w-full font-Poppins text-[1rem]'>{props.col4}</p>
        <p className='text-center w-full font-Poppins text-[1rem]'>{props.col5}</p>
        <div className='absolute -right-28 -translate-y-2 flex-row hidden group-hover:flex'>
            <button className='w-20 h-8 mr-2 border rounded bg-pink-red text-[1.125rem] text-white font-semibold'>cancel</button>
            <button className='w-20 h-8 border rounded bg-pink-red text-[1.125rem] text-white font-semibold'>refund</button>
        </div>
    </div>
  );
}