import React from "react";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import Transactions from "./pages/Transactions";
import CoinView from "./pages/CoinView";
import EventsView from "./pages/EventsView";
import EventsTracking from "./pages/EventsTracking";
import CreateEvent from "./pages/CreateEvent";
import ModifyEvent from "./pages/ModifyEvent";
import Login from "./pages/Login";
import TransactionDetails from "./pages/TransactionDetails";
import { TransactionProvider } from "./hooks/useTransaction";
import RequireAuth from "./components/RequireAuth";
import Menu from "./pages/Menu";
export default function App() {
  return (
    <React.StrictMode>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Menu />
            </RequireAuth>
          }
        />
        <Route
          path="/transactions"
          element={
            <RequireAuth>
              <TransactionProvider>
                <Transactions />
              </TransactionProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/transaction/:transactionId"
          element={
            <RequireAuth>
              <TransactionProvider>
                <TransactionDetails />
              </TransactionProvider>
            </RequireAuth>
          }
        />

        <Route
          path="/coinOverview"
          element={
            <RequireAuth>
              <CoinView />
            </RequireAuth>
          }
        />
        <Route
          path="/eventView"
          element={
            <RequireAuth>
              <EventsView />
            </RequireAuth>
          }
        />
        <Route
          path="/eventTracking"
          element={
            <RequireAuth>
              <EventsTracking />
            </RequireAuth>
          }
        />
        <Route
          path="/createEvent"
          element={
            <RequireAuth>
              <CreateEvent />
            </RequireAuth>
          }
        />
        <Route
          path="/modifyEvent/:eventId"
          element={
            <RequireAuth>
              <ModifyEvent />
            </RequireAuth>
          }
        />
      </Routes>
    </React.StrictMode>
  );
}
