import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function RequireAuth({ children }) {
  const { user } = useAuth();
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    if (!user) {
      const timer = setTimeout(() => {
        setDelay(false);
      }, 2000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup the timeout on unmount
    }
  }, [user]);

  if (!user && !delay) {
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    return (
      <main className="text-center flex justify-center items-center p-10">
        Redirecting to login in 2 seconds...
      </main>
    ); // Optional: You can show a message while waiting
  }

  return children;
}
