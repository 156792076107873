import React, { useState } from "react";
import AddPointsPopup from "./AddPointsPopup";

export default function Array(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="flex flex-col mt-8 px-[4.5rem] ">
      <div className="flex flex-row ">
        <p className=" text-center w-full font-Poppins text-[1.125rem] font-semibold">
          event ID
        </p>
        <p className=" w-full text-center font-Poppins text-[1.125rem] font-semibold">
          user ID
        </p>
        <p className=" w-full text-center font-Poppins text-[1.125rem] font-semibold">
          date
        </p>
        <p className=" w-full text-center font-Poppins text-[1.125rem] font-semibold">
          target
        </p>
        <p className="  w-full text-center font-Poppins text-[1.125rem] font-semibold">
          points
        </p>
      </div>
      <div className={`flex flex-col mt-4 relative`}>
        {isPopupOpen && <AddPointsPopup onClose={closePopup} />}
        {props.children}
      </div>
      <div className="flex flex-row mx-auto mt-8 w-full">
        <p className="text-[#aaa9a9] cursor-pointer mr-4 ml-auto">1</p>
        <p className="text-[#aaa9a9] cursor-pointer mr-4">{">"}</p>
        <p className="text-[#aaa9a9] cursor-pointer mr-4">212</p>
        <button
          className="w-[250px] h-12 border rounded bg-pink-red text-[1.125rem] text-white font-semibold -translate-y-3 ml-auto"
          onClick={openPopup}
        >
          Add points manually
        </button>
      </div>
    </div>
  );
}
