import { useEffect, useRef } from "react";

const Popover = ({ content, children, isVisible, setIsVisible }) => {
  const popoverRef = useRef(null);

  const togglePopover = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible]);

  return (
    <div className="relative" ref={popoverRef}>
      <div onClick={togglePopover}>{children}</div>
      {isVisible && (
        <div className="absolute top-10 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-md py-2 px-2 z-10 w-fit">
          {content}
        </div>
      )}
    </div>
  );
};

export default Popover;
