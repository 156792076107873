import { useState } from "react";

const Checkbox = ({ label, checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
    onChange(newValue); // Propagate the state change to parent component if needed
  };

  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-black rounded border-gray-300 focus:ring-black"
        checked={isChecked}
        onChange={handleChange}
      />
      <span className="text-sm font-medium">{label}</span>
    </label>
  );
};

export default Checkbox;
