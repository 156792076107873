import React, { useState, useEffect } from "react";
import Header from "../components/Header";

const CreateEvent = () => {
  const [name, setName] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventTarget, setEventTarget] = useState("All");
  const [cashbackFormula, setCashbackFormula] = useState("");
  const [message, setMessage] = useState("");
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(
          "https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events/event_details"
        );
        const data = await response.json();
        setEventOptions(data);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !eventId || !eventTarget || !cashbackFormula) {
      setMessage("Please fill out all fields.");
      return;
    }

    const eventDetails = {
      eventId,
      planTarget: eventTarget,
      planFormula: `${cashbackFormula} * amount`,
      planName: name,
    };
    try {
      const response = await fetch(
        "https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        setMessage("Event created successfully!");
      } else {
        const errorData = await response.json();
        setMessage(`Failed to create event: ${errorData.message}`);
      }
    } catch (error) {
      setMessage("An error occurred: " + error.message);
    }
  };

  return (
    <div>
      <Header />
      <h1 className="text-[2rem] mt-8 font-Poppins font-bold px-[150px]">
        Event Creation
      </h1>
      <form
        onSubmit={handleSubmit}
        className="mt-8 space-y-4 w-[30rem] mx-auto"
      >
        <div className="flex flex-col">
          <label className="font-Poppins text-lg">Event Name</label>
          <select
            value={eventId}
            onChange={(e) => setEventId(e.target.value)}
            className="border border-gray-400 rounded p-2"
          >
            <option value="">Select an event</option>
            {eventOptions.map((event) => (
              <option key={event.eventId} value={event.eventId}>
                {event.eventType}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label className="font-Poppins text-lg">Target</label>
          <select
            value={eventTarget}
            onChange={(e) => setEventTarget(e.target.value)}
            className="border border-gray-400 rounded p-2"
          >
            <option value="All">All</option>
            <option value="prestige">prestige</option>
            <option value="premium">premium</option>
            <option value="prestige pro">prestige pro</option>
            <option value="premium pro">premium pro</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="font-Poppins text-lg">Formula</label>
          <div className="flex items-center">
            <span className="mr-2">Amount * </span>
            <input
              type="text"
              value={cashbackFormula}
              onChange={(e) => setCashbackFormula(e.target.value)}
              className="border border-gray-400 rounded p-2 flex-grow"
            />
            <span className="ml-2"> = cashback</span>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="font-Poppins text-lg">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-400 rounded p-2"
          />
        </div>
        <button
          type="submit"
          className="bg-pink-red text-white py-2 px-4 rounded hover:bg-dark-pink-red"
        >
          Create Event
        </button>
      </form>
      {message && <p className="mt-4 font-Poppins text-lg">{message}</p>}
    </div>
  );
};

export default CreateEvent;
