import TransactionsTable from "../components/transactions/TransactionsTable";
import Header from "../components/Header";
import SearchBar from "../components/transactions/SearchBar";

export default function Transactions() {
  return (
    <main className="background-gradient ">
      <Header />
      <div className="space-y-[40px]">
        <SearchBar />
        <TransactionsTable />
      </div>
    </main>
  );
}
