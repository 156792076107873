import { useState } from "react";
import DateRangePicker from "../DateRangePicker";
import Dropdown from "../Dropdown";
import TextField from "../TextField";
import useTransaction from "../../hooks/useTransaction";
export default function SearchBar() {
  const [showFilter, setShowFilter] = useState(false);
  const { filterState, setFilterState, defaultFilterState, search } =
    useTransaction();

  const availableFields = ["client id", "transaction id", "nom"].map(
    (field) => ({
      item: field,
      closeOnClick: true,
    })
  );
  return (
    <div className="flex justify-center align-items  ">
      <div className="flex-col flex">
        <div className="bg-main-dark flex  rounded-full pb-1">
          <div className="rounded-full flex justify-center align-items bg-main p-4 w-[500px]">
            <DateRangePicker />
          </div>
          <div
            className="p-2 pr-5 flex justify-center align-items cursor-pointer"
            onClick={search}
          >
            <img
              src="/search-icon.svg"
              width={30}
              height={30}
              alt="search icon"
            />
          </div>
        </div>
        {showFilter ? (
          <div className="  bg-main-dark rounded-b-2xl ml-[30px] mr-[60px]">
            <div className="">
              <div className="flex justify-end  text-white text-[12px] pr-[20px]">
                <div
                  className="  pb-1 px-4 flex cursor-pointer"
                  onClick={() => {
                    setShowFilter(false);
                    setFilterState(defaultFilterState);
                  }}
                >
                  <p>moins de filtres</p>
                  <img
                    src="/up-arrow.svg"
                    width={10}
                    height={10}
                    className="text-gray-500 mx-1"
                    alt="up arrow"
                  />
                </div>
              </div>
              <div className="flex gap-4 w-full  justify-center">
                <Dropdown
                  label={
                    <p className="rounded-full inline-flex justify-center w-full  text-white shadow-xl  py-2 px-4  text-sm font-medium  hover:bg-main focus:outline-none ">
                      {filterState.selectedField}
                      <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </p>
                  }
                  items={availableFields.filter((el) => {
                    return el != filterState.selectedField;
                  })}
                  onSelect={(el) => {
                    setFilterState({ ...filterState, selectedField: el });
                  }}
                />
                {filterState.selectedField === "nom" ? (
                  <div>
                    <TextField
                      placeholder="prénom"
                      value={filterState.firstName}
                      onChange={(value) => {
                        setFilterState({ ...filterState, firstName: value });
                      }}
                    />
                    <TextField
                      placeholder="nom"
                      value={filterState.lastName}
                      onChange={(value) => {
                        setFilterState({ ...filterState, lastName: value });
                      }}
                    />
                  </div>
                ) : (
                  <TextField
                    placeholder={filterState.selectedField}
                    value={filterState[filterState.selectedField]}
                    onChange={(value) => {
                      setFilterState({
                        ...filterState,
                        [filterState.selectedField]: value,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex justify-end  text-white text-[12px] pr-[80px]"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <div className="bg-main-dark rounded-b-full pb-1 px-4 flex cursor-pointer">
              <p>plus de filtres</p>
              <img
                src="/down-arrow.svg"
                width={10}
                height={10}
                className="text-gray-500 mx-1"
                alt="down arrow"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
