import React, { useState } from "react";

export default function ComparatifAccordeonDesktop(props) {
  const [dropped, setDropped] = useState(true);

  const drop = () => {
    setDropped(!dropped);
  };
  return (
    <div className="flex flex-col mt-8 ">
      <div onClick={drop} className="flex flex-row mb-2 cursor-pointer">
        <p className="w-full text-left font-Poppins text-[1.125rem] font-semibold">
          {props.title}
        </p>
        <img
          className={!dropped ? "rotate-180" : ""}
          src="arrow.svg"
          alt="arrow"
        />
      </div>
      <div className={` ${!dropped ? "hidden" : ""} flex flex-col`}>
        {props.children}
      </div>
    </div>
  );
}
