import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Dropdown from "./Dropdown";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
export default function Header({ withNavBar = false }) {
  const navigate = useNavigate();

  const [showCoinMenu, setShowCoinMenu] = useState(false);
  const { logout } = useAuth();
  const menuItemsDefault = [
    {
      item: (
        <button
          onClick={() => {
            navigate("/transactions");
          }}
        >
          Transactions
        </button>
      ),
      closeOnClick: true,
    },
    {
      item: (
        <button
          onClick={() => {
            setShowCoinMenu(!showCoinMenu);
          }}
        >
          B Partner Club
        </button>
      ),
      closeOnClick: false,
    },

    {
      item: (
        <button className="flex justify-between  w-full" onClick={logout}>
          <p>logout</p>
          <img src="/logout.svg" width={20} height={20} alt="logout" />
        </button>
      ),
      closeOnClick: true,
    },
  ];
  const itemsShowCoinMenu = [
    {
      item: (
        <button
          className="pl-6 "
          onClick={() => {
            navigate("/coinOverview");
            setShowCoinMenu(false);
          }}
        >
          Coin Overview
        </button>
      ),
      closeOnClick: true,
    },
    {
      item: (
        <button
          className="pl-6"
          onClick={() => {
            navigate("/eventView");
            setShowCoinMenu(false);
          }}
        >
          Event View
        </button>
      ),

      closeOnClick: true,
    },
    {
      item: (
        <button
          className="pl-6"
          onClick={() => {
            navigate("/eventTracking");
            setShowCoinMenu(false);
          }}
        >
          Event Tracking
        </button>
      ),
      closeOnClick: true,
    },
  ];
  const [menuItems, setMenuItems] = useState(menuItemsDefault);
  useEffect(() => {
    if (showCoinMenu) {
      setMenuItems([
        ...menuItemsDefault.slice(0, -1),
        ...itemsShowCoinMenu,
        menuItemsDefault[menuItemsDefault.length - 1],
      ]);
    } else {
      setMenuItems(menuItemsDefault);
    }
  }, [showCoinMenu]);

  return (
    <div>
      <div className="w-full p-5 cursor-pointer flex justify-between ">
        <img
          src="/logo-b-partner.svg"
          width={90}
          height={90}
          alt="logo b partner"
          onClick={() => {
            navigate("/");
          }}
        />
        {withNavBar && <CoinNavBar />}
        <div className="flex items-center">
          <Dropdown
            label={
              <img
                src="/hamburger-menu.svg"
                width={50}
                height={60}
                alt="menu"
                className="ml-[100px] transition-filter duration-300  hover-custom-filter"
              />
            }
            items={menuItems}
          />
        </div>
      </div>
    </div>
  );
}

const menuItems = [
  { title: "Coin Overview", path: "/coinOverview" },
  { title: "Event View", path: "/eventView" },
  { title: "Event Tracking", path: "/eventTracking" },
];

const CoinNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="justify-between items-center inline-flex">
      <div className="items-center gap-[100px] flex">
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.path;
          return (
            <div
              key={index}
              className={`text-[#353a43] text-base font-normal font-['Poppins'] leading-relaxed relative ${
                isActive ? "active" : ""
              }`}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.title}
              {isActive && <span className="custom-underline"></span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
