// src/pages/TransactionDetails.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Table from "../components/Table";
import useTransaction from "../hooks/useTransaction";
import RefundRow from "../components/transactions/RefundRow";

export default function TransactionDetails() {
  const { transactionId } = useParams();
  const [transactionDetails, setTransactionDetails] = useState({});
  const { fetchTransactionDetails, formatDateFromSQL } = useTransaction();

  useEffect(() => {
    const getTransactionDetails = async () => {
      const transactionData = await fetchTransactionDetails(transactionId);
      setTransactionDetails(transactionData);
    };
    getTransactionDetails();
  }, []);

  const clientInfo = [
    { label: "Prénom", value: transactionDetails.first_name },
    { label: "Nom", value: transactionDetails.last_name },
    { label: "Nom Supplémentaire", value: transactionDetails.additional_name },
    { label: "Email", value: transactionDetails.email },
    { label: "Nationalité 1", value: transactionDetails.nationality_1 },
    { label: "Rôles", value: transactionDetails.roles },
    { label: "Genre", value: transactionDetails.gender },
    { label: "Numéro d'ID", value: transactionDetails.id_number },
    { label: "Pays de Naissance", value: transactionDetails.country_of_birth },
    {
      label: "ID du Pays de Naissance",
      value: transactionDetails.country_of_birth_id,
    },
    {
      label: "Créé le",
      value: formatDateFromSQL(transactionDetails.created_at),
    },
    { label: "Statut d'Emploi", value: transactionDetails.statut_emploi },
    { label: "Revenu Annuel", value: transactionDetails.revenu_annuel },
    {
      label: "Source Principale de Revenu",
      value: transactionDetails.main_source_revenue,
    },
    {
      label: "Raison d'Ouverture du Compte",
      value: transactionDetails.raison_open_account,
    },
    { label: "ID KYC Table1", value: transactionDetails.table1_kyc_id },
    { label: "Nationalité 2", value: transactionDetails.nationality_2 },
    { label: "Nationalité 3", value: transactionDetails.nationality_3 },
    { label: "Numéro de Téléphone", value: transactionDetails.phone_number },
    {
      label: "Date de Naissance",
      value: formatDateFromSQL(transactionDetails.date_of_birth),
    },
    { label: "Ville de Naissance", value: transactionDetails.city_of_birth },
    { label: "Statut de Signature", value: transactionDetails.signed_status },
    { label: "Numéro Fiscal", value: transactionDetails.fiscal_number },
    { label: "Offre Client", value: transactionDetails.client_offer },
    { label: "VIP", value: transactionDetails.VIP ? "Oui" : "Non" },
    { label: "Employé", value: transactionDetails.employee ? "Oui" : "Non" },
  ];

  const transactionInfo = [
    { label: "Montant", value: transactionDetails.amount },
    {
      label: "Type de Transaction",
      value: transactionDetails.transaction_type,
    },
    {
      label: "Statut de Transaction",
      value: transactionDetails.transaction_status,
    },
    { label: "ID d'Événement", value: transactionDetails.event_id },
    { label: "Code d'Événement", value: transactionDetails.event_code },
    { label: "ID de Carte", value: transactionDetails.card_id },
    { label: "Devise", value: transactionDetails.currency },
    {
      label: "Frais de Transaction",
      value: transactionDetails.transaction_fee,
    },
    {
      label: "Libellé de Transaction",
      value: transactionDetails.transaction_libel,
    },
    {
      label: "Date de Transaction",
      value: formatDateFromSQL(transactionDetails.transaction_date),
    },
    {
      label: "Date de Règlement",
      value: formatDateFromSQL(transactionDetails.transaction_settlement_date),
    },
    { label: "Opérateur", value: transactionDetails.operator },
    { label: "ID de Société", value: transactionDetails.company_id },
  ];

  const accountInfo = [
    { label: "Nom du compte", value: transactionDetails.account_name },
    {
      label: "Nom du compte à la création",
      value: transactionDetails.account_creation_name,
    },
    { label: "IBAN", value: transactionDetails.iban },
    { label: "BIC", value: transactionDetails.bic },
    { label: "Devise", value: transactionDetails.account_currency },
    { label: "Solde", value: transactionDetails.account_balance / 100 },
    { label: "Statut du compte", value: transactionDetails.account_status },
    { label: "Type de compte", value: transactionDetails.account_type },
    { label: "ID de l'entreprise", value: transactionDetails.company_id },
    {
      label: "ID du représentant légal",
      value: transactionDetails.legal_represent_id,
    },
    {
      label: "Date de création",
      value: formatDateFromSQL(transactionDetails.created_at),
    },
    { label: "ID du statut", value: transactionDetails.status_id },
    {
      label: "Limite de transaction",
      value: transactionDetails.transaction_limit,
    },
    {
      label: "Limite quotidienne",
      value: transactionDetails.daily_limit / 100,
    },
    {
      label: "Limite mensuelle",
      value: transactionDetails.monthly_limit / 100,
    },
    {
      label: "Limite quotidienne utilisée",
      value: transactionDetails.daily_limit_used,
    },
    {
      label: "Limite mensuelle utilisée",
      value: transactionDetails.monthly_limit_used / 100,
    },
    {
      label: "Limite de transaction DD",
      value: transactionDetails.dd_transaction_limit / 100,
    },
    {
      label: "Limite mensuelle DD",
      value: transactionDetails.dd_monthly_limit / 100,
    },
    {
      label: "Limite mensuelle DD utilisée",
      value: transactionDetails.dd_monthly_limit_used / 100,
    },
    { label: "Date de clôture", value: transactionDetails.closed_at },
    { label: "Clôturé par", value: transactionDetails.closed_by },
    { label: "Compte arrêté", value: transactionDetails.is_account_arrested },
    { label: "Numéro de compte", value: transactionDetails.account_number },
    { label: "Code de tri", value: transactionDetails.sort_code },
    {
      label: "Valeur fixe de création du compte",
      value: transactionDetails.account_creation_hardvalue,
    },
    {
      label: "Montant disponible",
      value: transactionDetails.disposable_amount,
    },
    {
      label: "Montant disponible utilisé",
      value: transactionDetails.disposable_amount_used,
    },
    {
      label: "Soldes des devises",
      value: transactionDetails.currency_balances,
    },
    {
      label: "Commentaire sur le statut",
      value: transactionDetails.status_comment,
    },
    { label: "Mis à jour par", value: transactionDetails.updated_by },
    { label: "Compte de frais", value: transactionDetails.is_fee_account },
  ];
  const transactionFeesInfo = [
    {
      label: "label",
      value: transactionDetails.label,
    },
    { label: "ID de la transaction", value: transactionDetails.transaction_id },
    { label: "Montant des frais", value: transactionDetails.fees_amount },
    {
      label: "Type de frais de transaction",
      value: transactionDetails.transaction_fees_type,
    },
    {
      label: "Statut des frais de transaction",
      value: transactionDetails.transaction_fees_status,
    },
    { label: "Devise des frais", value: transactionDetails.currency_fees },
    {
      label: "Libellé des frais de transaction",
      value: transactionDetails.transaction_libel_fees,
    },
    {
      label: "Date des frais de transaction",
      value: formatDateFromSQL(transactionDetails.transaction_date_fees),
    },
    {
      label: "Date de règlement des frais de transaction",
      value: formatDateFromSQL(
        transactionDetails.transaction_settelment_date_fees
      ),
    },
    { label: "Opérateur", value: transactionDetails.operator },
    { label: "ID de l'entreprise", value: transactionDetails.company_id },
  ];

  const refundInfo = [
    {
      label: "Devise de remboursement",
      value: transactionDetails.refund_currency,
    },
    { label: "Valeur remboursée", value: transactionDetails.refund_amount },
  ];

  return (
    <main className="min-h-screen background-gradient">
      <Header />
      <div className="space-y-[80px]">
        <div className="container mx-auto p-6 flex">
          <div className="w-1/2 p-4">
            <h2 className="text-xl font-semibold mb-4 flex gap-4">
              Informations sur le client
              <TransparentTextComponent text={transactionDetails.client_id} />
            </h2>
            <Table data={clientInfo} />
            <div className="pt-12">
              <h2 className="text-xl font-semibold mb-4 flex gap-4">
                Informations sur le compte
                <TransparentTextComponent
                  text={transactionDetails.account_id}
                />
              </h2>
              <Table data={accountInfo} />
            </div>
          </div>

          <div className="w-1/2 p-4">
            <h2 className="text-xl font-semibold mb-4 flex gap-4">
              Informations sur la transaction{" "}
              <TransparentTextComponent text={transactionId} />
            </h2>
            <Table data={transactionInfo} />

            {transactionDetails.transaction_fees_id && (
              <>
                <h2 className="text-xl font-semibold mb-4 flex gap-4 pt-12">
                  Informations sur les frais{" "}
                  <TransparentTextComponent
                    text={transactionDetails.transaction_fees_id}
                  />
                </h2>
                <Table data={transactionFeesInfo} />
              </>
            )}

            {transactionDetails.refunded > 0 && (
              <div className="pt-12">
                <h2 className="text-xl font-semibold mb-4 flex gap-4">
                  Informations sur le remboursement
                </h2>
                <RefundRow
                  transactionId={transactionId}
                  transactionFeesId={transactionDetails.transaction_fees_id}
                  isHorizontal={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
const TransparentTextComponent = ({ text }) => {
  return (
    <div className="bg-white w-fit px-4 rounded-full">
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-900 to-black ">
        {text}
      </span>
    </div>
  );
};
