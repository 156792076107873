import React from "react";
import LignAccDesk from "../components/LignAccDesk.js";
import Header from "../components/Header.js";
import ComparatifAccordeonDesktop from "../components/eventsComponents/ComparatifAccordeonDesktop.js";
import EventList from "../components/eventsComponents/EventList.js";

export default function EventsView() {
  return (
    <div>
      <Header withNavBar={true} />
      <div className="w-full px-[150px]">
        <h1 className="text-[2rem] mt-8 font-Poppins font-bold">Event view</h1>
        <ComparatifAccordeonDesktop title="Informations générales">
          <LignAccDesk
            colored={true}
            title="Supply actuel"
            subtitle=""
            col1="loading"
            col2="loading"
          />
          <LignAccDesk
            colored={false}
            title="Coins mintés cette semaine"
            subtitle=""
            col1="loading"
            col2="loading"
          />
          <LignAccDesk
            colored={true}
            title="Coins distribués ce mois ci"
            subtitle=""
            col1="loading"
            col2="loading"
          />
          <LignAccDesk
            colored={false}
            title="Reste à minter"
            subtitle=""
            col1="loading"
            col2="loading"
          />
          <LignAccDesk
            colored={true}
            title="Holders"
            subtitle=""
            col1="loading"
            col2="loading"
          />
          <LignAccDesk
            colored={false}
            title="Coin Price"
            subtitle=""
            col1="0.88$"
            col2="0.77€"
          />
        </ComparatifAccordeonDesktop>
        <EventList />
      </div>
    </div>
  );
}
