import React from "react";
import Array from "../components/eventsComponents/Array.js";
import LignArray from "../components/eventsComponents/LignArray.js";
import Header from "../components/Header.js";
export default function EventsTracking() {
  return (
    <div>
      <Header withNavBar={true} />
      <div className="w-full px-[150px]">
        <h1 className="text-[2rem] mt-8 font-Poppins font-bold">
          Event tracking
        </h1>
        <div className="flex flex-col w-full ">
          {/* options de tri */}
          <div className="flex flex-row justify-between mt-14">
            <div className="flex flex-row">
              <label className="font-semibold text-[1.25rem]">
                Event ID
                <input
                  type="text"
                  className="ml-4 w-[12rem] bg-[#d9d9d9] pl-2 font-medium text-[1.12rem] h-8 outline-[#bbbaba]"
                ></input>
              </label>
            </div>
            <div className="flex flex-row">
              <label className="font-semibold text-[1.25rem]">
                User ID
                <input
                  type="text"
                  className="ml-4 w-[12rem] bg-[#d9d9d9] pl-2 font-medium text-[1.12rem] h-8 outline-[#bbbaba]"
                ></input>
              </label>
            </div>
            <div className="flex flex-row">
              <label className="font-semibold text-[1.25rem]">
                Date
                <select className="border ml-2">
                  <option value="=">=</option>
                  <option value=">">&gt;</option>
                  <option value="<">&lt;</option>
                </select>
                <input
                  type="date"
                  className="ml-4 w-[12rem] bg-[#d9d9d9] pl-2 font-medium text-[1.12rem] h-8 outline-[#bbbaba]"
                ></input>
              </label>
            </div>
            <div className="flex flex-row">
              <label className="font-semibold text-[1.25rem]">
                Target
                <select className="border ml-2">
                  <option value="all">all</option>
                  <option value="prestige">prestige</option>
                  <option value="premium">premium</option>
                  <option value="pro-prestige">pro-prestige</option>
                  <option value="pro-premium">pro-premium</option>
                </select>
              </label>
            </div>
          </div>
          <Array>
            <LignArray
              col1="E3U6645fH"
              col2="uAZ24FDggHitj67z"
              col3="22-12-2024"
              col4="prestige"
              col5="2.8"
            />
            <LignArray
              col1="J8L3465sK"
              col2="xTY56GHjkLmpo89u"
              col3="18-11-2024"
              col4="premium"
              col5="3.1"
            />
            <LignArray
              col1="P9M2543dL"
              col2="qWE78UJlkBnoi45r"
              col3="25-10-2024"
              col4="prestige"
              col5="4.2"
            />
            <LignArray
              col1="A2K9876mN"
              col2="aSD34VBhjCxyz56s"
              col3="12-09-2024"
              col4="premium"
              col5="1.9"
            />
            <LignArray
              col1="D5F1247bO"
              col2="dFR90GHlmOklp12t"
              col3="05-08-2024"
              col4="prestige"
              col5="3.7"
            />
            <LignArray
              col1="K7Y6589cP"
              col2="mNB12DFgjPrstu34v"
              col3="19-07-2024"
              col4="premium"
              col5="2.5"
            />
            <LignArray
              col1="H3J5648eQ"
              col2="zQW45EDfkOuvwx78y"
              col3="30-06-2024"
              col4="prestige"
              col5="3.9"
            />
            <LignArray
              col1="T8B2346gR"
              col2="bNM34PLghRstuv12x"
              col3="15-05-2024"
              col4="premium"
              col5="2.0"
            />
            <LignArray
              col1="W9U1234iS"
              col2="cBV67XKlmPyzab34w"
              col3="10-04-2024"
              col4="prestige"
              col5="4.5"
            />
            <LignArray
              col1="Q4L3465jT"
              col2="vML56BNklAyzcd56z"
              col3="28-03-2024"
              col4="premium"
              col5="1.8"
            />
            <LignArray
              col1="M1G5647uV"
              col2="yHN89JKlmBxyz01c"
              col3="07-02-2024"
              col4="prestige"
              col5="2.3"
            />
            <LignArray
              col1="L6Z7890wX"
              col2="eRP23QVhiNopqr45a"
              col3="21-01-2024"
              col4="premium"
              col5="3.4"
            />
          </Array>
        </div>
      </div>
    </div>
  );
}
