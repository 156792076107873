import { useNavigate } from "react-router-dom";
const Card = ({ title, description, buttonAction, imagePath }) => {
  const navigate = useNavigate();
  return (
    <div className="w-[222px] h-[361px] relative ">
      <div className="w-[222px] h-[347px] bg-[#FBF8F8] left-0 top-[14px] absolute  rounded-lg shadow flex-col justify-start items-start inline-flex">
        <div className="h-[139px] flex-col justify-start items-start flex"></div>
        <div className=" h-52 p-4 flex-col justify-start items-start gap-2 flex bg-white">
          <div className=" h-[130px] flex-col justify-start items-start gap-0.5 flex">
            <div className=" text-[#0c0318] text-xl font-bold  leading-normal">
              {title}
            </div>
            <div className=" text-[#0c0318] text-xs font-normal  leading-tight">
              {description}
              <br />
            </div>
          </div>

          <div className="justify-center items-center   flex w-full">
            <button
              className="h-[38px] px-10 py-5 bg-[#cc133e] rounded   justify-center items-center  gap-3 flex text-white text-xl font-bold  cursor-pointer"
              onClick={() => {
                navigate(`${buttonAction}`);
              }}
            >
              Accéder
            </button>
          </div>
        </div>
      </div>
      <div className="w-[161px] h-[161px] pl-[28.31px]  pr-[28.13px] pt-[26.37px] pb-[26px] left-[25px] top-0 absolute justify-center items-center inline-flex">
        <div className="w-[104.56px] h-[108.63px] relative">
          <img src={imagePath} alt={title} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};
export default Card;
