import { useState, useEffect } from "react";
const BASE_URL =
  "https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events";

const useEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getEvents = async () => {
    try {
      const response = await fetch(`${BASE_URL}/active`);
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(
          `Network response was not ok: ${response.statusText}. Details: ${errorDetails}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching all events:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsData = await getEvents();
        setEvents(eventsData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return { events, loading, error };
};

export default useEvents;
