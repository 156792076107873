import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "../components/TextField";
import useAuth from "../hooks/useAuth";

export default function Login() {
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState({ userName: "", password: "" });
  const { login } = useAuth();
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      await login(loginState);
      navigate("/");
      setError("");
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  return (
    <main className="flex justify-center items-center   background-gradient">
      <div className="w-[400px]">
        <h2 className="text-2xl font-bold flex justify-center pb-12">
          Connexion
        </h2>
        {error && <p className="text-main pb-6 text-center">{error}</p>}
        <TextField
          label={<div className="text-white">Nom</div>}
          onChange={(value) =>
            setLoginState({ ...loginState, userName: value })
          }
          required={true}
        />
        <TextField
          type="password"
          label={<div className="text-white">Mot de passe</div>}
          onChange={(value) =>
            setLoginState({ ...loginState, password: value })
          }
          required={true}
        />
        <div className="flex justify-center items-center pt-10">
          <button
            className="bg-main-dark hover:bg-main text-white font-bold py-2 px-4 rounded-full"
            onClick={handleLogin}
          >
            Se connecter
          </button>
        </div>
      </div>
    </main>
  );
}
