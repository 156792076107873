import React, { useState, useEffect, useRef } from "react";
import useEvents from "../../hooks/useEvents.js";
import Card from "./Card.js";
import { Link } from "react-router-dom";

function EventList() {
  const { events, loading, error, fetchEvents } = useEvents(); // Assurez-vous que fetchEvents est disponible pour rafraîchir la liste après suppression
  const [selectedEventId, setSelectedEventId] = useState(null);
  const containerRef = useRef(null);
  const handleCardClick = (eventId) => {
    setSelectedEventId(eventId);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedEventId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://umlrt7zni4.execute-api.eu-west-1.amazonaws.com/api/events/delete?id=${selectedEventId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        alert("Event deleted successfully!");
        setSelectedEventId(null);
        fetchEvents(); // Rafraîchit la liste des événements après suppression
      } else {
        alert("Failed to delete event.");
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  if (loading) {
    return <div className="loader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div ref={containerRef}>
      <div className="w-[90%] mx-auto overflow-x-auto mt-16 scrollbar-custom">
        <div className="flex space-x-24 mb-4">
          {events.map((card, index) => (
            <Card
              key={index}
              eventId={card.planId}
              event={card.eventId}
              target={card.planTarget}
              formula={card.planFormula}
              name={card.planName}
              isSelected={card.planId === selectedEventId}
              onCardClick={() => handleCardClick(card.planId)}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-center mt-8">
        <Link to="/createEvent">
          <button className="mr-2 w-40 h-10 border rounded bg-pink-red text-[1.125rem] text-white font-semibold">
            Créer
          </button>
        </Link>
        {selectedEventId && (
          <>
            <Link to={`/modifyEvent/${selectedEventId}`}>
              <button className="ml-2 w-40 h-10 border rounded bg-pink-red text-[1.125rem] text-white font-semibold">
                Modifier
              </button>
            </Link>
            <button
              onClick={handleDelete}
              className="ml-2 w-40 h-10 border rounded bg-red-600 text-[1.125rem] text-white font-semibold"
            >
              Supprimer
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default EventList;
