const TextField = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  isRequired = false,
}) => {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="mb-4">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1 ">
          {label}
        </label>
      )}
      <input
        type={type}
        className="block w-full bg-main-light px-3 py-2  rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm text-gray-900"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        required={isRequired}
      />
    </div>
  );
};

export default TextField;
