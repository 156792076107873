import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import useAuth from "./useAuth";
const TransactionContext = createContext({});
const apiUrl = process.env.REACT_APP_API_URL;

export function TransactionProvider({ children }) {
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateFromSQL = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(now.getDate() - 30);

  const defaultFilterState = {
    dates: [formatDate(oneWeekAgo), formatDate(now)],
    selectedField: "client id",
    firstName: "",
    lastName: "",
    "client id": "",
    "transaction id": "",
    showRefund: true,
    showNonRefund: true,
    showPendingRefund: true,
  };
  const { setUser, user } = useAuth();
  const dateRangePickerRef = useRef(null);
  const location = useLocation();
  const [filterState, setFilterState] = useState(defaultFilterState);
  const [refresh, setRefresh] = useState(false);
  const [unfilteredRows, setUnfilteredRows] = useState([]);
  const [rows, setRows] = useState([]);
  const header = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setUser(null);
      return;
    }
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };
  };
  const getCurrentDateTimeForSQL = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const triggerRefund = async (refundRow, transactionId) => {
    const transactionDetails = await fetchTransactionDetails(transactionId);
    // || "BE62510007547061"
    const queryParams = {
      cr_name: `${transactionDetails.first_name} ${transactionDetails.last_name}`,
      cr_iban: transactionDetails.iban,
      refund_currency: transactionDetails.currency,
      ...refundRow,
    };
    refund(queryParams);
  };
  const search = async () => {
    const dates = dateRangePickerRef?.current?.getDates("yyyy-mm-dd");
    let queryParams = {
      startDate: dates && dates[0],
      endDate: dates && dates[1],
    };
    if (dates) {
      setFilterState({
        ...filterState,
        dates: dateRangePickerRef?.current?.getDates("dd/mm/yyyy"),
      });
    }

    if (filterState.selectedField === "client id" && filterState["client id"]) {
      queryParams.clientId = filterState["client id"];
    } else if (
      filterState.selectedField === "transaction id" &&
      filterState["transaction id"]
    ) {
      queryParams.transactionId = filterState["transaction id"];
    } else if (
      filterState.selectedField === "nom" &&
      filterState.firstName &&
      filterState.lastName
    ) {
      queryParams.firstName = filterState.firstName;
      queryParams.lastName = filterState.lastName;
    } else if (filterState.selectedField === "nom" && filterState.firstName) {
      queryParams.firstName = filterState.firstName;
    } else if (filterState.selectedField === "nom" && filterState.lastName) {
      queryParams.lastName = filterState.lastName;
    }
    let path = "transactions";

    const url = new URL(`${apiUrl}/${path}`);
    url.search = new URLSearchParams(queryParams).toString();

    try {
      const res = await axios.get(url.toString(), header());
      setUnfilteredRows(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const refund = async (queryParams) => {
    let path = "processRefund";
    const url = new URL(`${apiUrl}/${path}`);
    url.search = new URLSearchParams(queryParams).toString();

    try {
      const res = await axios.post(url.toString(), {}, header());
      console.log(res.status);
    } catch (error) {
      console.error("Error processing refund:", error);
    }
  };

  const deletePendingRefund = async (pendingRefundId) => {
    const queryParams = {
      pendingRefundId,
    };
    const path = "pendingRefund";
    const url = new URL(`${apiUrl}/${path}`);
    url.search = new URLSearchParams(queryParams).toString();

    try {
      const res = await axios.delete(url.toString(), header());
      return res.data[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTransactionDetails = async (transactionId) => {
    const queryParams = {
      transactionId: transactionId,
    };
    const path = "transactionDetails";
    const url = new URL(`${apiUrl}/${path}`);
    url.search = new URLSearchParams(queryParams).toString();

    try {
      const res = await axios.get(url.toString(), header());
      return res.data[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createPendingRefund = async (rowData) => {
    rowData["isPending"] = true;
    try {
      await axios.post(`${apiUrl}/createRefundRow`, rowData, header());
    } catch (error) {
      console.error("Error creating pending refund:", error);
      alert("Error creating pending refund");
    }
  };

  const getRefunds = async (transactionId, transactionFeesId) => {
    const queryParams = {
      transactionId,
      transactionFeesId,
    };
    const path = "refunds";
    const url = new URL(`${apiUrl}/${path}`);
    url.search = new URLSearchParams(queryParams).toString();
    try {
      const res = await axios.get(url.toString(), header());
      return res.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterRows = () => {
    let newRows = unfilteredRows;

    if (
      !filterState.showRefund &&
      !filterState.showNonRefund &&
      !filterState.showPendingRefund
    ) {
      newRows = [];
    } else {
      if (
        filterState.showRefund &&
        filterState.showNonRefund &&
        filterState.showPendingRefund
      ) {
        newRows = unfilteredRows;
      } else if (
        filterState.showRefund &&
        filterState.showNonRefund &&
        !filterState.showPendingRefund
      ) {
        newRows = newRows.filter((row) => row?.refunded !== 2);
      } else if (
        filterState.showRefund &&
        !filterState.showNonRefund &&
        filterState.showPendingRefund
      ) {
        newRows = newRows.filter((row) => row?.refunded !== 0);
      } else if (
        filterState.showRefund &&
        !filterState.showNonRefund &&
        !filterState.showPendingRefund
      ) {
        newRows = newRows.filter(
          (row) => row?.refunded === 1 || row?.refunded === 3
        );
      } else if (
        !filterState.showRefund &&
        filterState.showNonRefund &&
        filterState.showPendingRefund
      ) {
        newRows = newRows.filter((row) => row?.refunded !== 1);
      } else if (
        !filterState.showRefund &&
        filterState.showNonRefund &&
        !filterState.showPendingRefund
      ) {
        newRows = newRows.filter((row) => row?.refunded === 0);
      } else if (
        !filterState.showRefund &&
        !filterState.showNonRefund &&
        filterState.showPendingRefund
      ) {
        newRows = newRows.filter(
          (row) => row?.refunded === 2 || row?.refunded === 3
        );
      } else if (
        filterState.showRefund &&
        filterState.showNonRefund &&
        !filterState.showPendingRefund
      ) {
        newRows = newRows.filter((row) => row?.refunded !== 2);
      }
    }

    setRows(newRows);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      search();
    }
  }, [location.pathname, refresh, user]);

  useEffect(() => {
    filterRows();
  }, [filterState, unfilteredRows]);

  const memoizedValue = useMemo(
    () => ({
      filterState,
      defaultFilterState,
      rows,
      dateRangePickerRef,
      refresh,
      search,
      filterRows,
      setFilterState,
      refund,
      fetchTransactionDetails,
      createPendingRefund,
      getRefunds,
      deletePendingRefund,
      triggerRefund,
      getCurrentDateTimeForSQL,
      setRefresh,
      formatDateFromSQL,
    }),
    [filterState, rows, refresh]
  );

  return (
    <TransactionContext.Provider value={memoizedValue}>
      {children}
    </TransactionContext.Provider>
  );
}

export default function useTransaction() {
  return useContext(TransactionContext);
}
