import { useEffect, useState } from "react";
import useTransaction from "../../hooks/useTransaction";
import useAuth from "../../hooks/useAuth";
import Modal from "../Modal";

export default function RefundRow({
  transactionId,
  transactionFeesId,
  maxTransactionRefundAmount,
  maxFeesRefundAmount,
  isHorizontal = true,
}) {
  const {
    getRefunds,
    deletePendingRefund,
    triggerRefund,
    refresh,
    setRefresh,
    getCurrentDateTimeForSQL,
    formatDateFromSQL,
  } = useTransaction();
  const { user } = useAuth();
  const [refunds, setRefunds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const refundsPerPage = isHorizontal ? 4 : 10;

  useEffect(() => {
    async function getData() {
      const data = await getRefunds(transactionId, transactionFeesId);
      const combinedRefunds = [
        ...data.pendingRefundRows.map((row) => ({ ...row, type: "pending" })),
        ...data.refundRows.map((row) => ({ ...row, type: "validated" })),
      ];
      setRefunds(combinedRefunds);
    }
    getData();
  }, [refresh]);

  const validate = async (refund) => {
    const refundRow = {
      dashboard_user_id: user.userId,
      refund_amount: refund?.refund_amount,
      refund_description: refund?.refund_description,
      refund_currency: refund?.refund_currency,
      refund_date: getCurrentDateTimeForSQL(),
    };
    if (refund?.transaction_id) {
      if (maxTransactionRefundAmount < refund?.refund_amount) {
        setModalMessage(
          `Erreur : le montant doit être inférieur à ${maxTransactionRefundAmount}`
        );
        setIsModalVisible(true);
        return;
      }
      refundRow["transaction_id"] = refund?.transaction_id;
      refundRow["fee_type"] = "transaction";
    } else {
      if (maxFeesRefundAmount < refund?.refund_amount) {
        setModalMessage(
          `Erreur : le montant doit être inférieur à ${maxFeesRefundAmount}`
        );
        setIsModalVisible(true);
        return;
      }
      refundRow["transaction_fees_id"] = refund?.transaction_fees_id;
      refundRow["fee_type"] = "frais";
    }
    await triggerRefund(refundRow, transactionId);
    await deletePendingRefund(refund.pending_refund_id);
    setTimeout(() => {
      setRefresh(!refresh);
    }, 2000);
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const paginatedRefunds = refunds.slice(
    currentPage * refundsPerPage,
    currentPage * refundsPerPage + refundsPerPage
  );

  return (
    <div className={`flex gap-3 ${!isHorizontal && "flex-col gap-0"} `}>
      {currentPage > 0 && (
        <div
          className="py-2 px-3 rounded-lg bg-gray2 my-2 bg-opacity-30 mix-blend-lighten cursor-pointer"
          onClick={handlePrevious}
        >
          <div className="flex justify-center items-center h-full text-2xl">
            <p>{"<"}</p>
          </div>
        </div>
      )}
      {paginatedRefunds.map((refund, index) => (
        <div
          className={`relative  py-2 px-3 h-fit rounded-lg my-2 bg-opacity-70 mix-blend-lighten ${
            refund.type === "pending" ? "bg-main-dark" : "bg-gray2"
          } ${isHorizontal && "w-[330px]"}`}
          key={index}
        >
          <div className="flex gap-2 pt-1">
            <p>
              Remboursement{" "}
              {refund?.transaction_id ? "transaction" : "de frais"}
            </p>

            <div
              className={`absolute right-2 ${
                !isHorizontal && "flex justify-center gap-4 items-center"
              }`}
            >
              {!isHorizontal && (
                <p className="pl-10">{formatDateFromSQL(refund.refund_date)}</p>
              )}
              <TransparentTextComponent
                text={
                  refund.type === "pending"
                    ? refund.pending_refund_id
                    : refund.refund_id
                }
              />
            </div>
          </div>
          <div className="flex-col flex items-start ml-2 pl-2 mt-2 border-l">
            <p>
              {refund.refund_amount}{" "}
              {refund?.refund_currency === "EUR" && " \u20AC - "}
              <span>
                {refund.type === "pending"
                  ? `posté par ${refund.user_name}`
                  : `validé par ${refund.user_name}`}
              </span>
            </p>
            <p
              className={`rounded-lg text-left px-2 max-w-[75%] ${
                refund.type === "pending"
                  ? "bg-main-dark"
                  : "bg-gray2 bg-opacity-60"
              }`}
            >
              {refund?.refund_description}
            </p>
          </div>
          {refund.type === "pending" && (
            <div className="absolute right-2 bottom-2 flex gap-1">
              <div
                className="bg-main-dark rounded-full cursor-pointer hover:bg-main"
                onClick={() => {
                  deletePendingRefund(refund?.pending_refund_id);
                  setRefunds(
                    refunds.filter(
                      (el) => el.pending_refund_id !== refund.pending_refund_id
                    )
                  );
                }}
              >
                <img
                  src="/delete-button.svg"
                  width={30}
                  height={30}
                  alt="delete"
                />
              </div>
              {user?.userType === "admin" && (
                <div
                  className="bg-main-dark rounded-full cursor-pointer hover:bg-main"
                  onClick={() => validate(refund)}
                >
                  <img src="/ok-mark.svg" width={30} height={30} alt="ok" />
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      {(currentPage + 1) * refundsPerPage < refunds.length && (
        <div
          className="py-2 px-3 rounded-lg bg-gray2 my-2 bg-opacity-30 mix-blend-lighten cursor-pointer"
          onClick={handleNext}
        >
          <div className="flex justify-center items-center h-full text-2xl">
            <p>{">"}</p>
          </div>
        </div>
      )}
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
}

const TransparentTextComponent = ({ text }) => {
  return (
    <div className="border-2 border-white w-fit px-3 rounded-full">
      <span>{text}</span>
    </div>
  );
};
